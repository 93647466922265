.react-datepicker {
    /*height: 430px;*/
    width: 382px;
    border: 0 !important;

    /* box-shadow */
    -webkit-box-shadow: 3px 3px 14px -2px rgba(0,0,0,0.61);
    -moz-box-shadow: 3px 3px 14px -2px rgba(0,0,0,0.61);
    box-shadow: 3px 3px 14px -2px rgba(0,0,0,0.61);
}

.react-datepicker__month-container {
    /*height: 326px; */
    width: inherit;
    float: none !important;
}

.react-datepicker__day-name {
    text-transform: uppercase;
    font-weight: normal !important;
    font-size: 12px;
    padding-top: 8px;
    color: #CCCCCC !important;
}

.react-datepicker__day {
    background-color: #FCFCFC;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #D8D8D8 !important;
    font-weight: bold;
    height: 40px !important;
    width: 40px !important;
    line-height: 40px !important;
    margin-right: 8px !important;
    border-radius: 2px !important;
}

.react-datepicker__header {
    background: #ffffff !important;
    border-bottom: none !important;
}

.react-datepicker__day--today {
    color: #2196F3 !important;
}

.react-datepicker__current-month {
    color: #5F7081 !important;
}

.react-datepicker__day--selected {
    background: #2196F3 !important;
    color: #ffffff !important;
}

.react-datepicker__day--selected:hover {
    background: #187BCA !important;
}

.react-datepicker__day-name {
    color: #B9B9B9 !important;
}

.react-datepicker__day--disabled {
    cursor: not-allowed;
}

.react-datepicker__day--in-range {
    background-color: #F0F0F0;
}

.datepickerStartAndEndDate {
    background: #2196F3 !important;
    color: #ffffff !important;
}

/* vai ficar deprecated com o custom header? */
.react-datepicker__current-month:first-letter {
    text-transform: uppercase;
    font-weight: normal;
}

.react-datepicker-popper {
    margin-top: 20px !important;
    padding-bottom: 20px;
}

.selected {
    border-color: #68A9EF !important;
}

.notSelected {
    border-color: #E8ECEF !important;
}
