/* 
    ############################ ATENTION ############################
    If you made any changes to this classes, you will need to replicate 
    these changes on foodi-app pasting the minified css class into
    PlusInfo.component.tsx - variable contentStyle.

    For the moment, we don't have a better solution
*/

figure{
    margin:0!important
}

p.title, .ck.ck-button.ck-heading_heading_title {
    color: hsl(0, 0%, 14%);
    font-family: 'Open Sans', 'sans-serif';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
}

p.subtitle, .ck.ck-button.ck-heading_heading_subtitle {
    color: 	hsl(0, 0%, 22%);
    font-family: 'Open Sans', 'sans-serif';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
}

p.content, .ck.ck-button.ck-heading_heading_content {
    color: 	hsl(0, 0%, 48%);
    font-family: 'Open Sans', 'sans-serif';
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
}

.react-time-picker__wrapper {
    border: none !important;
}